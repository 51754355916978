// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { Identify, identify } from "@amplitude/analytics-browser"
import { useLDClient } from "launchdarkly-react-client-sdk"
import { useEffect, useRef, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { isInternal } from "../analytics"
import authService from "./auth-service"
import { ACCOUNT_KEY, CID_KEY, REDIRECT_URI_KEY } from "./constants"
import { ONPREM_API_CERT_KEY } from "../utils/constants"
import { isDSCC, isONPREM } from "../utils/env"

const setHeaderConfig = (headerNode, user) => {
  if (!headerNode) {
    return
  }
  headerNode.platform = "secureDefault"
  headerNode.config = {
    ...headerNode.config,
    user: {
      firstName: user.profile.given_name,
      lastName: user.profile.family_name,
      email: user.profile.email,
    },
    items: ["brand", "help", "apps", "nav", isDSCC() && "context"].filter(
      Boolean
    ),
  }

  headerNode.ldFlags = {
    ...headerNode.ldFlags,
    "glcp-contextual-help": true,
    "glcp-service-centric-experience-phase-1": true,
  }
}

export function LoginRedirect({ children }) {
  const client = useLDClient()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const headerNode = useRef(document.querySelector("greenlake-header"))

  // ensure user is logged in
  useEffect(() => {
    async function loadUser() {
      const user = await authService.getUser()

      if (user) {
        setIsAuthenticated(true)
        setHeaderConfig(headerNode.current, user)
      } else {
        // store destination url (if not already present) so it can be loaded after authentication
        if (!sessionStorage.getItem(REDIRECT_URI_KEY)) {
          sessionStorage.setItem(
            REDIRECT_URI_KEY,
            window.location.href.replace(window.location.origin, "")
          )
        }

        // if we have cid in localStorage, login with that value
        // e.g. when opening a new tab from an existing session
        const cidFromStorage = localStorage.getItem(CID_KEY)
        const account = JSON.parse(localStorage.getItem(ACCOUNT_KEY))
        const workspaceId = account?.workspaceId ?? null
        if (cidFromStorage) {
          return authService.login({ cid: cidFromStorage, workspaceId })
        }

        navigate("/login", { replace: true })
      }
    }

    // check for onprem cert acceptance before auth flow
    if (isONPREM() && !sessionStorage.getItem(ONPREM_API_CERT_KEY)) {
      if (!sessionStorage.getItem(REDIRECT_URI_KEY)) {
        sessionStorage.setItem(
          REDIRECT_URI_KEY,
          window.location.href.replace(window.location.origin, "")
        )
      }
      navigate("/accept-certificate", { replace: true })
    } else if (!isAuthenticated) {
      loadUser()
    }
  }, [isAuthenticated, navigate])

  // update LD client with authenticated user to fetch user-targetted flags
  useEffect(() => {
    async function ldIdentify() {
      const user = await authService.getUser()
      const appCustID = authService.getUserCtx(user.access_token)
      const ldUser = {
        key: appCustID,
        email: user.profile.email,
        custom: { applicationCustomerId: appCustID },
      }
      // log on rejection, will fail if client was unable to initialize
      client.identify(ldUser).catch((error) => console.error(error))
    }

    // only run once LD client is initialized and user is authenticated
    if (client && isAuthenticated) {
      ldIdentify()
    }
  }, [client, isAuthenticated])

  // set amplitude user properties post-login
  useEffect(() => {
    async function ampIdentify() {
      const user = await authService.getUser()
      const event = new Identify()
      event.setOnce("isInternal", isInternal(user?.profile?.email))
      identify(event)
    }

    if (isAuthenticated) {
      ampIdentify()
    }
  }, [isAuthenticated])

  if (!isAuthenticated) {
    return null
  }

  return children ? children : <Outlet />
}
