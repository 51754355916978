// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { NotificationsPlugin } from "@storage/react-notifications-plugin"
import { Box, Button } from "grommet"
import { Notification } from "grommet-icons"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useAuthZ } from "../hooks"
import { getTestIdAttribute, header } from "../utils/test-ids"

export default function NotificationsButton() {
  const { permissions } = useAuthZ()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <ErrorBoundary
      fallbackRender={() => null}
      onError={(error) => console.error(error)}
    >
      <NotificationsPlugin
        alignment="bottom-end"
        dataTestId={header.notifications.plugin}
        offset={5}
        onAnnouncementsNotificationsViewDetailsClicked={() =>
          navigate("/announcements")
        }
        onIssuesNotificationsViewDetailsClicked={() => navigate("/issues")}
      >
        <Button
          disabled={!permissions.includes("issue.read")}
          slot="target"
          icon={<Notification size="medium" />}
          tip={{
            content: (
              <Box {...getTestIdAttribute(header.notifications.tip)}>
                {t("tipNotifications")}
              </Box>
            ),
          }}
          {...getTestIdAttribute(header.notifications.icon)}
        />
      </NotificationsPlugin>
    </ErrorBoundary>
  )
}
