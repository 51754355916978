// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box } from "grommet"
import { useLayoutEffect, useRef, useState } from "react"
import { Outlet } from "react-router-dom"
import FlashMessage from "./flash-message"
import TaskBanner from "./task-banner"

export default function GlobalLayout() {
  const headerNode = useRef(document.querySelector("greenlake-header"))
  const [headerNodeHeight, setHeaderNodeHeight] = useState(0)

  useLayoutEffect(() => {
    if (headerNode.current) {
      setHeaderNodeHeight((prevHeaderNodeHeight) =>
        !prevHeaderNodeHeight
          ? headerNode.current.offsetHeight
          : prevHeaderNodeHeight
      )
    }
  }, [])

  return (
    <Box fill="horizontal" height={`calc(100vh - ${headerNodeHeight}px)`}>
      <FlashMessage />
      <Outlet />
      <TaskBanner />
    </Box>
  )
}
