// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, DropButton } from "grommet"
import { User } from "grommet-icons"
import { useEffect, useState } from "react"
import { useUser } from "../hooks"
import ProfileMenu from "./profile-menu"
import { getTestIdAttribute, userProfile } from "../utils/test-ids"

export default function UserAvatar() {
  const user = useUser()
  const [name, setName] = useState(null)

  useEffect(() => {
    if (user) {
      setName(`${user.profile.given_name} ${user.profile.family_name}`)
    }
  }, [user])

  return (
    <DropButton
      dropAlign={{ top: "bottom", right: "right" }}
      dropContent={<ProfileMenu name={name} />}
      icon={
        <Box align="center">
          <User color="text-strong" size="medium" />
        </Box>
      }
      {...getTestIdAttribute(userProfile.picture)}
    />
  )
}
