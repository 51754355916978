// (c) Copyright 2024 Hewlett Packard Enterprise Development LP
import { Box, Header, Text } from "grommet"
import { ErrorBoundary } from "react-error-boundary"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import BrandButton from "./brand-button"
import MiniLaunchPad from "./mini-launchpad"
import { getTestIdAttribute, header } from "../utils/test-ids"

const Pipe = () => (
  <Text
    color="text-weak"
    margin={{ horizontal: "8px" }}
    style={{ userSelect: "none" }}
  >
    |
  </Text>
)

// Allows child elements to query its width
const Container = styled.div`
  container-type: inline-size;
`

/**
 * Padding style below matches the padding of DSCC header with padding of GLP header
 * This ensures that GLP header and DSCC header icons are vertically aligned
 */
const StyledHeader = styled(Header)`
  @container (min-width: 769px) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @container (min-width: 1440px) {
    padding-left: 48px;
    padding-right: 48px;
  }
`

export default function ShellHeader({
  brandTitle,
  children,
  dataTestId = header.container,
  serviceTitle,
}) {
  const { t } = useTranslation()

  return (
    <Container>
      <StyledHeader
        border={{ side: "bottom", color: "border-weak", size: "1px" }}
        color="background"
        height="72px"
        pad={{ horizontal: "12px" }}
        {...getTestIdAttribute(dataTestId)}
      >
        <Box align="center" direction="row">
          <BrandButton>{brandTitle}</BrandButton>
          {serviceTitle && (
            <>
              <Pipe />
              <ErrorBoundary
                fallback={<Text color="text-strong">{t(serviceTitle)}</Text>}
              >
                <MiniLaunchPad serviceTitle={serviceTitle} />
              </ErrorBoundary>
            </>
          )}
        </Box>
        <Box align="center" direction="row" gap="6px">
          {children}
        </Box>
      </StyledHeader>
    </Container>
  )
}
