// (c) Copyright 2024 Hewlett Packard Enterprise Development LP

import { useFlags } from "launchdarkly-react-client-sdk"
import { useTranslation } from "react-i18next"
import GlobalSearch from "./global-search"
import IssuesButton from "./issues-button"
import NotificationsButton from "./notifications-button"
import ShellHeader from "./shell-header"
import UserAvatar from "./user-avatar"
import { Products } from "../utils/constants"

export default function DSCCHeader({ serviceTitle }) {
  const { t } = useTranslation()
  const {
    "shell-enable-search-1": shellEnableSearch1,
    "shell-enable-notifications-1": shellEnableNotifications1,
  } = useFlags()

  return (
    <ShellHeader brandTitle={t(Products.DSCC)} serviceTitle={serviceTitle}>
      {shellEnableSearch1 && <GlobalSearch service={serviceTitle} />}
      {shellEnableNotifications1 ? <NotificationsButton /> : <IssuesButton />}
      <UserAvatar />
    </ShellHeader>
  )
}
